import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@common/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard  {

  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getSession()
      .pipe(
        map(session => {
          if (session.isValid()) {
            return true;
          } else {
            // not logged in so redirect to signIn page with the return url
            void this.router.navigate(['/auth/sign-in'], {queryParams: {returnUrl: state.url}});
            return false;
          }
        }),
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedGuard  {

  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.getSession()
      .pipe(
        map(session => {
          if (session.isValid()) {
            void this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        }),
      );
  }

}

export const enterpriseGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (typeof route.data['enterprise'] !== 'boolean') {
    throw new Error('Missing configuration for the user enterprise attribute used with enterprise guard');
  }

  const enterprise = Boolean(route.data['enterprise']);

  return authService.getUser()
    .pipe(
      map(user => {
        if (user.enterprise === enterprise) {
          return true;
        } else {
          void router.navigate(['/'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }),
    );
};
